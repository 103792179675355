import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Card,
  IconButton,
  Modal,
  TextField,
  Stack,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import axiosInstance from "axiosConfig";

interface Note {
  title: string;
  content: string;
}

interface NotesProps {
  data?: Note[]; // Data is now an array of Note objects
  id: any;
}

const Notes: React.FC<NotesProps> = ({ data = [], id }) => {
  const [notes, setNotes] = useState<Note[]>(data); // Initialize with `data` prop
  const [expandedNoteIndex, setExpandedNoteIndex] = useState<number | null>(
    null
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newNote, setNewNote] = useState({ title: "", content: "" });

  const handleExpandClick = (noteIndex: number) => {
    setExpandedNoteIndex(expandedNoteIndex === noteIndex ? null : noteIndex);
  };

  const handleAddNote = async () => {
    try {
      if (!newNote.title || !newNote.content) return;

      // Create new note
      const newNoteData = { title: newNote.title, content: newNote.content };

      // A) Update local state (UI feedback)
      const updatedNotes = [...notes, newNoteData];
      setNotes(updatedNotes);

      // Clear fields + close modal
      setNewNote({ title: "", content: "" });
      setIsModalOpen(false);

      // B) Send updated notes array to the backend
      const response = await axiosInstance.patch(`/admin/request/${id}`, {
        notes: updatedNotes, // Send the full updated notes array to the server
      });

      console.log("Note added successfully:", response.data);
    } catch (error) {
      console.error("Error adding note:", error);
    }
  };

  return (
    <Card sx={{ padding: "20px" }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h6">الملاحظات</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsModalOpen(true)}
          startIcon={<AddIcon />}
          sx={{ backgroundColor: "#2E6CB6", gap: "8px" }}
        >
          إضافة ملاحظة
        </Button>
      </Box>

      <Box>
        {notes?.map((note, index) => (
          <Box
            key={note.content + index} // Ensure unique key
            sx={{
              border: "1px solid #E0E0E0",
              borderRadius: "8px",
              marginBottom: "8px",
              padding: "10px",
              backgroundColor: "#FAFAFA",
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ cursor: "pointer" }}
              onClick={() => handleExpandClick(index)}
            >
              <Typography>{note.title}</Typography>
              <IconButton>
                <ExpandMoreIcon
                  sx={{
                    transform:
                      expandedNoteIndex === index
                        ? "rotate(180deg)"
                        : "rotate(0deg)",
                    transition: "transform 0.3s",
                  }}
                />
              </IconButton>
            </Box>
            {expandedNoteIndex === index && (
              <Box sx={{ marginTop: "10px" }}>
                <Typography>{note.content}</Typography>
              </Box>
            )}
          </Box>
        ))}
      </Box>

      {/* Add Note Modal */}
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            padding: 4,
          }}
        >
          <Typography variant="h6" mb={2}>
            أضف ملاحظة
          </Typography>
          <Stack spacing={2}>
            <TextField
              label="عنوان الملاحظة"
              fullWidth
              value={newNote.title}
              onChange={(e) =>
                setNewNote({ ...newNote, title: e.target.value })
              }
            />
            <TextField
              label="المحتوى"
              multiline
              rows={4}
              fullWidth
              value={newNote.content}
              onChange={(e) =>
                setNewNote({ ...newNote, content: e.target.value })
              }
            />
            <Button
              variant="contained"
              onClick={handleAddNote}
              sx={{ backgroundColor: "#2E6CB6" }}
            >
              إضافة
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Card>
  );
};

export default Notes;
