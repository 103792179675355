import { useLocation } from "react-router-dom";
import AddLawyer from "./AddLawyer";
import AddClient from "./AddClient";

const ConditionalEditComponent = ({ handleClientSelect }: any) => {
  const location = useLocation();
  const pathParts = location.pathname.split("/"); // Split the path to access the parameter

  const type = pathParts[pathParts.length - 1]; // Get the last part of the URL, which should be "clients" or "lawyers"

  if (type === "lawyers") {
    return <AddLawyer req={location?.state?.req} />;
  }

  // Default to Clients if `type` is not "lawyers"
  return <AddClient />;
};

export default ConditionalEditComponent;
