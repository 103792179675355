import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import CustomButton from "../common/CustomButton";
import { FormProps } from "interfaces/common";
import { styled } from "@mui/system";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: "12px",
    borderColor: "#D1D5DB",
    transition: "border-color 0.3s ease, box-shadow 0.3s ease",
    "& fieldset": {
      borderColor: "#D1D5DB",
    },
    "&:hover fieldset": {
      borderColor: "#2E6CB6",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#2E6CB6",
      boxShadow: `0 0 0 4px ${theme.palette.primary.light}33`,
    },
  },
  "& .MuiInputLabel-root": {
    fontSize: "0.9rem",
    color: "#6B7280",
  },
}));

const Form = ({
  register,
  handleSubmit,
  onFinishHandler,
  formLoading,
  agentData,
}: FormProps) => {
  const { t, i18n } = useTranslation();

  return (
    <Box>
      <Typography
        fontSize={28}
        fontWeight={700}
        color="#1F2937"
        margin={2}
        textAlign="right"
        mb={2}
      >
        {t("update_new_vendor")}
      </Typography>

      <Box
        mt={3}
        width={{
          xs: "100%", // Full width on extra small devices
          sm: "90%", // Slightly smaller on small devices
          md: "70%", // Moderate reduction on medium devices
          lg: "60%", // More reduction on large devices
          xl: "50%", // Half width on extra-large devices
        }}
        borderRadius="20px"
        padding="24px"
        bgcolor="#ffffff"
        boxShadow="0 2px 10px rgba(0, 0, 0, 0.1)"
      >
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            width: "100%",
          }}
          onSubmit={handleSubmit(onFinishHandler)}
        >
          <Stack direction="column" gap={4} width="100%">
            <StyledFormControl>
              <TextField
                id="username"
                label={t("enterClientName")}
                color="primary"
                variant="outlined"
                inputProps={{
                  style: {
                    fontSize: "1rem",
                  },
                }}
                defaultValue={agentData.username}
                {...register("username")}
                sx={{
                  direction: "rtl",
                }}
              />
            </StyledFormControl>
            <StyledFormControl>
              <TextField
                id="email"
                label={t("enterClientEmail")}
                color="primary"
                variant="outlined"
                inputProps={{ style: { fontSize: "1rem" } }}
                defaultValue={agentData.email}
                {...register("email")}
              />
            </StyledFormControl>
            <StyledFormControl>
              <TextField
                id="phone"
                label={t("enterClientPhone")}
                type="number"
                color="primary"
                variant="outlined"
                inputProps={{ style: { fontSize: "1rem" } }}
                defaultValue={agentData.phone}
                {...register("phone")}
              />
            </StyledFormControl>
            <StyledFormControl>
              <TextField
                id="id_document"
                label={t("idNumber")}
                type="number"
                color="primary"
                variant="outlined"
                inputProps={{ style: { fontSize: "1rem" } }}
                defaultValue={agentData.id_document}
                {...register("id_document")}
              />
            </StyledFormControl>
          </Stack>

          <Stack direction={{ xs: "column", sm: "row" }} gap={3} width="100%">
            <StyledFormControl>
              <TextField
                id="password"
                type="password"
                label={t("password")}
                color="primary"
                variant="outlined"
                inputProps={{ style: { fontSize: "1rem" } }}
                {...register("password")}
              />
            </StyledFormControl>
            <StyledFormControl>
              <TextField
                id="confirmPassword"
                type="password"
                label={t("confirmPassword")}
                color="primary"
                variant="outlined"
                inputProps={{ style: { fontSize: "1rem" } }}
                {...register("confirmPassword")}
              />
            </StyledFormControl>
          </Stack>

          <Box mt={2} textAlign="end">
            <CustomButton
              type="submit"
              title={t("update_new_vendor")}
              backgroundColor="#2E6CB6"
              color="#ffffff"
            />
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default Form;
