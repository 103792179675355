import React from "react";
import { useForm } from "@refinedev/react-hook-form";
import { FieldValues } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../axiosConfig";
import OfficeForm from "components/common/OfficeForm";

const EditOffice = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const Data = location.state?.agent;
  // asd
  const {
    register,
    handleSubmit,
    refineCore: { formLoading },
  } = useForm();

  const onFinishHandler = async (data: FieldValues) => {
    const updateData = {
      ...(data.admin_name && { admin_username: data.admin_name }),
      ...(data.email && { admin_email: data.email }),
      ...(data.phone && { admin_phone: data.phone }),
      ...(data.office_name && { Name: data.office_name }),
      ...(data.address && { Address: data.address }),
      ...(data.password && { password: data.password }),
    };
    console.log("🚀 ~ onFinishHandler ~ updateData:", updateData);

    try {
      const response = await axiosInstance.patch(
        `/superadmin/office/${Data.ID}`,
        updateData
      );

      if (response.status === 200) {
        console.log("🚀 ~ onFinishHandler ~ response:", response);
        navigate(-1);
      } else {
        console.log("Update failed:", response.data.message);
      }
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  return (
    <OfficeForm
      type="edit"
      register={register}
      handleSubmit={handleSubmit}
      formLoading={formLoading}
      onFinishHandler={onFinishHandler}
      handleImageChange={(file: any) => {
        console.log("Image change handler not implemented yet");
      }}
      propertyImage={{
        name: "",
        url: "",
      }}
      agent={{
        email: "",
      }}
      agentData={Data}
      control={undefined}
    />
  );
};

export default EditOffice;
