import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { AddButton, CustomButton } from "components";
import { Link, useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Pagination,
  PaginationItem,
  Modal,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import axios from "../../axiosConfig";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";

// Replace with your actual interface
interface RequestData {
  case_id: number | null;
  case_type: string | null;
  claim_value: number | null;
  created_at: string;
  defendant_name: string;
  description: string;
  document_type: string | null;
  files: Array<any>;
  id: number;
  judgment_document_path: string | null;
  location: string | null;
  national_address: string | null;
  notes: Array<any>;
  plaintiff_name: string;
  request_type: string;
  status: string;
  user: {
    username: string;
    address: string;
    email: string;
    // ...
  };
  // Add other fields as needed
}

const Case = () => {
  const [requests, setRequests] = useState<RequestData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedRequestType, setSelectedRequestType] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<string>("");

  // Date range states
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  // Sort logic
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items per page

  // For delete modal
  const [open, setOpen] = useState(false);
  const [requestToDelete, setRequestToDelete] = useState<RequestData | null>(
    null
  );

  const navigate = useNavigate();
  const { t } = useTranslation();

  // --------------------------
  // Fetch data from API on mount
  // --------------------------
  useEffect(() => {
    axios
      .get("/admin/get_requests")
      .then((response) => {
        setRequests(response.data || []);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error fetching requests:", error);
        setLoading(false);
      });
  }, []);

  // --------------------------
  // Unique values for dropdown filters
  // --------------------------
  const uniqueRequestTypes = useMemo(() => {
    const types = requests.map((req) => req.request_type).filter(Boolean);
    return Array.from(new Set(types));
  }, [requests]);

  const uniqueStatuses = useMemo(() => {
    const statuses = requests.map((req) => req.status).filter(Boolean);
    return Array.from(new Set(statuses));
  }, [requests]);

  // --------------------------
  // Filtered & Sorted Requests
  // --------------------------
  const filteredRequests = useMemo(() => {
    if (!requests) return [];

    return requests.filter((request) => {
      // ---- Filter by specific request types (Case and New Case) ----
      const isCaseOrNewCase =
        request.request_type === "Case" || request.request_type === "New Case";

      // ---- Search Filter (plaintiff_name) ----
      const matchesSearch =
        (request?.plaintiff_name &&
          request?.plaintiff_name
            .toLowerCase()
            .includes(searchQuery.toLowerCase())) ||
        searchQuery.trim() === "";

      // ---- Request Type Filter ----
      const matchesRequestType =
        !selectedRequestType || request.request_type === selectedRequestType;

      // ---- Status Filter ----
      const matchesStatus =
        !selectedStatus || request.status === selectedStatus;

      // ---- Date Range Filter ----
      let matchesDateRange = true;
      if (startDate && endDate) {
        const requestDate = new Date(request.created_at).getTime();
        matchesDateRange =
          requestDate >= startDate.getTime() &&
          requestDate <= endDate.getTime();
      } else if (startDate) {
        // If only startDate is selected, check if requestDate >= startDate
        const requestDate = new Date(request.created_at).getTime();
        matchesDateRange = requestDate >= startDate.getTime();
      } else if (endDate) {
        // If only endDate is selected, check if requestDate <= endDate
        const requestDate = new Date(request.created_at).getTime();
        matchesDateRange = requestDate <= endDate.getTime();
      }

      return (
        isCaseOrNewCase &&
        matchesSearch &&
        matchesRequestType &&
        matchesStatus &&
        matchesDateRange
      );
    });
  }, [
    requests,
    searchQuery,
    selectedRequestType,
    selectedStatus,
    startDate,
    endDate,
  ]);

  // --------------------------
  // Sorting Logic with useMemo
  // --------------------------
  const sortedRequests = useMemo(() => {
    if (!sortColumn) return filteredRequests;

    return [...filteredRequests].sort((a, b) => {
      let aVal: string | number = "";
      let bVal: string | number = "";

      switch (sortColumn) {
        case "plaintiff_name":
          aVal = a.plaintiff_name?.toLowerCase() || "";
          bVal = b.plaintiff_name?.toLowerCase() || "";
          break;
        case "request_type":
          aVal = a.request_type?.toLowerCase() || "";
          bVal = b.request_type?.toLowerCase() || "";
          break;
        case "request_creation_date":
          aVal = a.created_at ? new Date(a.created_at).getTime() : 0;
          bVal = b.created_at ? new Date(b.created_at).getTime() : 0;
          break;
        case "status":
          aVal = a.status?.toLowerCase() || "";
          bVal = b.status?.toLowerCase() || "";
          break;
        default:
          break;
      }

      if (aVal < bVal) return sortDirection === "asc" ? -1 : 1;
      if (aVal > bVal) return sortDirection === "asc" ? 1 : -1;
      return 0;
    });
  }, [filteredRequests, sortColumn, sortDirection]);

  // --------------------------
  // Pagination: slice sorted list
  // --------------------------
  const paginatedRequests = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return sortedRequests.slice(startIndex, startIndex + itemsPerPage);
  }, [sortedRequests, currentPage, itemsPerPage]);

  console.log("🚀 ~ paginatedRequests ~ paginatedRequests:", paginatedRequests);
  // --------------------------
  // Sorting Handler
  // --------------------------
  const handleSort = (column: string) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
    // Reset to page 1 whenever you change sorting
    setCurrentPage(1);
  };

  // --------------------------
  // Page Change
  // --------------------------
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setCurrentPage(page);
  };

  // --------------------------
  // Delete Modal Handlers
  // --------------------------
  const handleOpen = (request: RequestData) => {
    setRequestToDelete(request);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setRequestToDelete(null);
  };

  const handleDelete = () => {
    if (requestToDelete) {
      // Example delete endpoint
      const endpoint = `/api/superadmin/delete_entity/something/${requestToDelete.case_id}`;

      fetch(endpoint, {
        method: "DELETE",
      })
        .then((response) => {
          if (response.ok) {
            console.log("Deleted successfully");
            // Optionally refetch or remove item from state
            setRequests((prev) =>
              prev.filter((req) => req.case_id !== requestToDelete.case_id)
            );
          } else {
            console.log("Delete failed");
          }
          handleClose();
        })
        .catch((error) => {
          console.error("Error deleting:", error);
          handleClose();
        });
    }
  };

  // --------------------------
  // Reset All Filters
  // --------------------------
  const handleResetFilters = () => {
    setSearchQuery("");
    setSelectedRequestType("");
    setSelectedStatus("");
    setStartDate(null);
    setEndDate(null);
    setSortColumn(null);
    setSortDirection("asc");
    setCurrentPage(1);
  };

  // --------------------------
  // Custom Pagination Styling
  // --------------------------
  const CustomPaginationItem = styled(PaginationItem)(({ theme }) => ({
    "&.Mui-selected": {
      backgroundColor: "#2E6CB6",
      color: "#FFFFFF",
      borderRadius: 4,
    },
  }));

  const formatArabicDate = (dateString: string | number | Date) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("ar-EG", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    }).format(date);
  };
  return (
    <Box>
      {/* Delete Confirmation Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "35%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
            borderRadius: 2,
          }}
        >
          <Typography id="delete-modal-title" variant="h6" component="h2">
            {t("Are you sure you want to delete this request?")}
          </Typography>

          <Box
            sx={{
              justifyContent: "space-between",
              flexDirection: "row",
              display: "flex",
              m: 4,
            }}
          >
            <Box>
              <CustomButton
                type="submit"
                title={t("confirm")}
                backgroundColor="#C84630"
                color="#fcfcfc"
                handleClick={handleDelete}
              />
            </Box>
            <Box>
              <CustomButton
                type="button"
                title={t("cancel")}
                backgroundColor="#ccc"
                color="#000"
                handleClick={handleClose}
              />
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* Page Title */}
      <Typography fontSize={25} fontWeight={700} color="#11142d">
        {t("Requests")}
      </Typography>

      {/* Filters Container */}
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        gap={2}
        mt={2}
        mb={2}
      >
        {/* Search Input */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            border: "1px solid #ccc",
            borderRadius: 4,
            px: 2,
          }}
        >
          <SearchIcon />
          <input
            type="text"
            placeholder={t("Search by plaintiff name") as string}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{
              border: "none",
              outline: "none",
              padding: "8px",
            }}
          />
        </Box>

        {/* Status Dropdown */}
        <FormControl sx={{ minWidth: 140 }}>
          <InputLabel>{t("status")}</InputLabel>
          <Select
            value={selectedStatus}
            label={t("status")}
            onChange={(e) => setSelectedStatus(e.target.value)}
          >
            <MenuItem value="">{t("all-statuses")}</MenuItem>
            {uniqueStatuses.map((stat) => (
              <MenuItem key={stat} value={stat}>
                {stat}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <CustomButton
          title={t("reset-filters")}
          backgroundColor="#ccc"
          color="#000"
          handleClick={handleResetFilters}
        />

        {/* Add New Request Button */}
        <AddButton
          title={t("add_new_Cases")}
          handleClick={() => navigate("/Requests/create")}
          backgroundColor="#2E6CB6"
          color="#fcfcfc"
          icon={
            <img
              className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[60px] h-[60px] overflow-hidden"
              alt=""
              src={"/Group 239.svg"}
            />
          }
        />
      </Box>

      {/* Table Container */}
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow style={{ background: "rgba(220, 236, 255, 0.30)" }}>
              <TableCell />
              <TableCell
                onClick={() => handleSort("plaintiff_name")}
                style={{ cursor: "pointer" }}
              >
                {t("username")}
                {sortColumn === "plaintiff_name" &&
                  (sortDirection === "asc" ? " ▲" : " ▼")}
              </TableCell>
              <TableCell
                onClick={() => handleSort("request_type")}
                style={{ cursor: "pointer" }}
              >
                {t("request_type")}
                {sortColumn === "request_type" &&
                  (sortDirection === "asc" ? " ▲" : " ▼")}
              </TableCell>
              <TableCell
                onClick={() => handleSort("request_creation_date")}
                style={{ cursor: "pointer" }}
              >
                {t("created-at")}
                {sortColumn === "request_creation_date" &&
                  (sortDirection === "asc" ? " ▲" : " ▼")}
              </TableCell>
              <TableCell
                onClick={() => handleSort("status")}
                style={{ cursor: "pointer" }}
              >
                {t("status")}
                {sortColumn === "status" &&
                  (sortDirection === "asc" ? " ▲" : " ▼")}
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRequests.map((request) => (
              <TableRow key={request.id}>
                <TableCell>
                  <img alt="" src={"consulting.png"} />
                </TableCell>
                <TableCell>{request?.user.username}</TableCell>

                <TableCell>
                  {request.request_type === "Consultation" ? (
                    <img
                      className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[30px] h-[30px] overflow-hidden"
                      alt=""
                      src={"/consultingrectangle.svg"}
                    />
                  ) : request.request_type === "Appointment" ? (
                    <img
                      className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[30px] h-[30px] overflow-hidden"
                      alt=""
                      src={"/appointmentrectangle.svg"}
                    />
                  ) : request.request_type === "New Case" ? (
                    <img
                      className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[30px] h-[30px] overflow-hidden"
                      alt=""
                      src={"/caserectangle.svg"}
                    />
                  ) : request.request_type === "Case" ? (
                    <img
                      className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[30px] h-[30px] overflow-hidden"
                      alt=""
                      src={"/caserectangle.svg"}
                    />
                  ) : request.request_type === "Collecting" ? (
                    <img
                      className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[30px] h-[30px] overflow-hidden"
                      alt=""
                      src={"/collectingrectangle.svg"}
                    />
                  ) : request.request_type === "Consolation" ? (
                    <img
                      className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[30px] h-[30px] overflow-hidden"
                      alt=""
                      src={"/consultingrectangle.svg"}
                    />
                  ) : (
                    <img
                      className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[30px] h-[30px] overflow-hidden"
                      alt=""
                      src={"/enforcementrectangle.svg"}
                    />
                  )}
                </TableCell>
                <TableCell>
                  {/* {new Date(request.created_at).toLocaleString("ar-GB", {
                    hour12: false,
                  })} */}
                  {formatArabicDate(request.created_at)}
                </TableCell>
                <TableCell>
                  {request.status === "Approved" ? "مفتوح" : "قيد الانتظار"}
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleOpen(request)}>
                    <DeleteIcon style={{ color: "#8DA7C8" }} />
                  </IconButton>
                  <Link
                    to="/requests/show"
                    state={{ request }}
                    style={{ textDecoration: "none" }}
                  >
                    <IconButton>
                      <InfoIcon style={{ color: "#8DA7C8" }} />
                    </IconButton>
                  </Link>
                </TableCell>
              </TableRow>
            ))}

            {paginatedRequests.length === 0 && (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  {t("No results found")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <Box display="flex" justifyContent="center" mt={2}>
        <Pagination
          count={Math.ceil(sortedRequests.length / itemsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="standard"
          renderItem={(item) => (
            <CustomPaginationItem
              components={{
                previous: () => (
                  <img
                    src="/keyboard_double_arrow_right.svg"
                    alt="Previous"
                    style={{ width: "24px", height: "24px" }}
                  />
                ),
                next: () => (
                  <img
                    src="/keyboard_double_arrow_left.svg"
                    alt="Next"
                    style={{ width: "24px", height: "24px" }}
                  />
                ),
              }}
              {...item}
            />
          )}
        />
      </Box>
    </Box>
  );
};

export default Case;
