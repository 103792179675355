import React, { useEffect, useState } from "react";
import { FieldValues } from "react-hook-form";
import { useGetIdentity, useNotification } from "@refinedev/core";
import RequestForm from "components/requests/RequestForm";
import { Modal, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm } from "@refinedev/react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomButton } from "components";
import axios from "../../axiosConfig";

const CreateRequest = () => {
  const { data: user } = useGetIdentity({
    v3LegacyAuthProviderCompatible: true,
  });
  const notification = useNotification();
  const {
    refineCore: { onFinish, formLoading },
    register,
    handleSubmit,
  } = useForm();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState(false);
  const location = useLocation();
  const [data, setData] = useState(() => {
    const savedData = localStorage.getItem("dataUser");
    return savedData ? JSON.parse(savedData) : location.state?.user;
  });
  const [dataUser, setDataUser] = useState(() => {
    const savedData = localStorage.getItem("data");
    return savedData ? JSON.parse(savedData) : location.state?.agent;
  });
  console.log("🚀 ~ const[data,setData]=useState ~ user:", data);
  console.log("🚀 ~ const[dataUser,setDataUser]=useState ~ lawyer:", dataUser);

  useEffect(() => {
    if (location.state?.user !== undefined) {
      setData(location.state.user);
      localStorage.setItem("dataUser", JSON.stringify(location.state.user));
    }
    if (location.state?.agent !== undefined) {
      setDataUser(location.state.agent);
      localStorage.setItem("data", JSON.stringify(location.state.agent));
    }
  }, [location]);
  const onFinishHandler = async (formData: FieldValues) => {
    // Ensure notification is defined
    if (!notification?.open) {
      console.error("Notification object is undefined.");
      return;
    }

    // Check if dataUser is undefined and show a notification
    if (!dataUser) {
      notification.open({
        type: "error",
        message: t("userSelectionRequired"),
        description: t("userSelectionRequiredDescription"),
      });
      return;
    }

    // Map formData to match the API request structure
    const requestBody = {
      request_type: formData.request_type || "Legal Advice",
      description: formData.description || "Need advice on contract law",
      user_id: data?.id,
      case_type: formData.case_type || "Contract",
      location: formData.location || "Unknown",
      plaintiff_name: formData.plaintiff_name || "Unknown",
      defendant_name: formData.defendant_name || "Unknown",
      national_address: formData.national_address || "Not Provided",
      amount: formData.amount || null,
      notes: formData?.notes || null,
      uploadedFile: formData.uploadedFile || null,
      user: data,
      lawyer: dataUser,
    };

    console.log("🚀 ~ Reformatted Request Body:", requestBody);

    try {
      const response = await axios.post("/admin/create_request", requestBody, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = response.data;
      console.log("API Response:", result);

      // Show success message
      setSuccessMessage(true);
      localStorage.removeItem("requestForm");
    } catch (error) {
      console.log("API Request Error:", error);
    }
  };
  const handleClose = () => {
    setSuccessMessage(false);
    navigate("/Requests");
  };

  return (
    <div>
      <RequestForm
        type="Create"
        register={register}
        onFinish={onFinish}
        formLoading={formLoading}
        handleSubmit={handleSubmit}
        agent={user}
        onFinishHandler={onFinishHandler}
        handleImageChange={function (file: any): void {}}
        propertyImage={{
          name: "",
          url: "",
        }}
        control={undefined}
        agentData={undefined}
      />
      <Modal
        open={successMessage}
        onClose={handleClose}
        aria-labelledby="success-modal-title"
        aria-describedby="success-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "35%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
            borderRadius: 2,
          }}
        >
          <img
            src="/letsiconscheckfill.svg"
            alt="Next"
            style={{ width: "59px", height: "59px" }}
          />
          <Typography
            id="success-modal-title"
            variant="h4"
            component="h2"
            m={5}
          >
            {t("userAddedSuccessfully")}
          </Typography>
          <CustomButton
            type="submit"
            title={t("confirm")}
            backgroundColor="#2E6CB6"
            color="#fcfcfc"
            handleClick={handleClose}
          />
          {/* <Button variant="contained" onClick={handleClose}> */}

          {/* </Button> */}
        </Box>
      </Modal>
    </div>
  );
};

export default CreateRequest;
