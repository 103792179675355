import Button from "@mui/material/Button";

import { AddButtonProps } from "interfaces/common";

const AddButton = ({
  type,
  title,
  backgroundColor,
  color,
  fullWidth,
  icon,
  handleClick,
  disabled,
}: AddButtonProps) => {
  return (
    <Button
      disabled={disabled}
      type={type === "submit" ? "submit" : "button"}
      sx={{
        flex: fullWidth ? 1 : "unset",
        padding: "10px 15px",
        width: fullWidth ? "100%" : "fit-content",
        minWidth: 180,
        backgroundColor,
        color,
        fontSize: 16,
        borderRadius: 20,
        fontWeight: 600,
        gap: "20px",
        textTransform: "capitalize",
        "&:hover": {
          opacity: 0.9,
          backgroundColor,
        },
      }}
      onClick={handleClick}>
      {title}
      {icon}
    </Button>
  );
};

export default AddButton;
