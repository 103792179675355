import React from "react";
import { Paper, IconButton, InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

interface SearchBarProps {
  placeholder?: string;
  searchQuery: string;
  onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({
  placeholder = "Search...",
  searchQuery,
  onSearchChange,
}) => {
  return (
    <Paper
      component="div"
      elevation={0}
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: "60%",
        borderRadius: "8px",
        border: "1px solid #E0E7FF",
        backgroundColor: "#F8FAFF",
      }}
    >
      <IconButton sx={{ p: "10px" }} aria-label="search">
        <SearchIcon />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeholder}
        value={searchQuery}
        onChange={onSearchChange}
      />
    </Paper>
  );
};

export default SearchBar;
