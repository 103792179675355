import { Box, Typography, Button, Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import { ProfileProps } from "interfaces/common";

function checkImage(url: any) {
  const img = new Image();
  img.src = url;
  return img.width !== 0 && img.height !== 0;
}

const Profile = ({
  type,
  name,
  avatar,
  email,
  id,
  address,
  gender,
  id_document,
  phone,
}: ProfileProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleEditUser = () => {
    navigate(`/vendors/edit/${id}`, {
      state: { agent: { ID: id } },
    });
  };

  return (
    <Box
      position="relative"
      bgcolor="#fff"
      minHeight="100vh"
      overflow="hidden"
      textAlign="right"
      fontFamily="Wahran"
      p={2}>
      <Typography
        position="relative"
        m={2}
        textAlign={{ xs: "center", md: "right" }}
        fontSize={{ xs: "24px", sm: "32px", md: "48px" }}>
        {t("clients")}
      </Typography>

      <Box
        position="relative"
        width="100%"
        maxWidth="1068px"
        mx="auto"
        p={2}
        borderRadius="8px"
        bgcolor="#F5F7FB"
        border="1px solid #d9e3f1">
        <Box
          position="relative"
          display="flex"
          flexDirection="column"
          alignItems="flex-start">
          <Box
            position="relative"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexDirection="row"
            width={"100%"}
            p={2}>
            <Box display="flex" alignItems="center" flexDirection="row">
              <Avatar
                src={checkImage(avatar) ? avatar : "/usercon.png"}
                alt=""
                sx={{
                  width: { xs: "80px", md: "112px" },
                  height: { xs: "80px", md: "112px" },
                  marginLeft: 5,
                }}
              />
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column">
                <Typography
                  fontSize={{ xs: "20px", sm: "25px", md: "36px" }}
                  color="#111923"
                  textAlign="center">
                  {t("personal_info")}
                </Typography>
                {type === "client" ? (
                  <img
                    style={{
                      width: "80px",
                      height: "80px",
                    }}
                    alt=""
                    src={"/client.svg"}
                  />
                ) : (
                  <img
                    style={{
                      width: "80px",
                      height: "80px",
                    }}
                    alt=""
                    src={"/lawyer.svg"}
                  />
                )}
              </Box>
            </Box>
            <Box display="flex" justifyContent="center">
              <Button
                onClick={handleEditUser}
                variant="contained"
                sx={{
                  backgroundColor: "#2E6CB6",
                  borderRadius: "20px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.25)",
                  width: { xs: "80%", sm: "60%", md: "193px" },
                  "&:hover": {
                    backgroundColor: "#1A4C96", // Change this to your desired hover color
                  },
                }}>
                {t("edit_info")}

                <EditIcon sx={{ marginRight: "20px" }} />
              </Button>
            </Box>
          </Box>

          <Box
            mt={3}
            display="flex"
            justifyContent="space-between"
            width="100%"
            flexWrap="wrap">
            <Box
              display="flex"
              flexDirection="column"
              width={{ xs: "100%", sm: "45%", md: "30%" }}
              mb={2}>
              <Typography
                fontSize="16px"
                color="#111923"
                fontWeight={600}
                sx={{ marginBottom: "8px" }}>
                {t("user name")}
              </Typography>
              <Box
                p={2}
                border="1px solid #d9e3f1"
                borderRadius="8px"
                bgcolor="#fff">
                <Typography fontSize="16px">{name}</Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              width={{ xs: "100%", sm: "45%", md: "30%" }}
              mb={2}>
              <Typography
                fontSize="16px"
                color="#111923"
                fontWeight={600}
                sx={{ marginBottom: "8px" }}>
                {t("email")}
              </Typography>
              <Box
                p={2}
                border="1px solid #d9e3f1"
                borderRadius="8px"
                bgcolor="#fff">
                <Typography fontSize="16px">{email}</Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              width={{ xs: "100%", sm: "45%", md: "30%" }}
              mb={2}>
              <Typography
                fontSize="16px"
                color="#111923"
                fontWeight={600}
                sx={{ marginBottom: "8px" }}>
                {t("address")}
              </Typography>
              <Box
                p={2}
                border="1px solid #d9e3f1"
                borderRadius="8px"
                bgcolor="#fff">
                <Typography fontSize="16px">{address}</Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              width={{ xs: "100%", sm: "45%", md: "30%" }}
              mb={2}>
              <Typography
                fontSize="16px"
                color="#111923"
                fontWeight={600}
                sx={{ marginBottom: "8px" }}>
                {t("phone")}
              </Typography>
              <Box
                p={2}
                border="1px solid #d9e3f1"
                borderRadius="8px"
                bgcolor="#fff">
                <Typography fontSize="16px">{phone}</Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              width={{ xs: "100%", sm: "45%", md: "30%" }}
              mb={2}>
              <Typography
                fontSize="16px"
                color="#111923"
                fontWeight={600}
                sx={{ marginBottom: "8px" }}>
                {t("id_document")}
              </Typography>
              <Box
                p={2}
                border="1px solid #d9e3f1"
                borderRadius="8px"
                bgcolor="#fff">
                <Typography fontSize="16px">{id_document}</Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              width={{ xs: "100%", sm: "45%", md: "30%" }}
              mb={2}>
              <Typography
                fontSize="16px"
                color="#111923"
                fontWeight={600}
                sx={{ marginBottom: "8px" }}>
                {t("gender")}
              </Typography>
              <Box
                p={2}
                border="1px solid #d9e3f1"
                borderRadius="8px"
                bgcolor="#fff">
                <Typography fontSize="16px">{gender}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Profile;
