import { useState } from "react";
import { useForm } from "@refinedev/react-hook-form";
import { FieldValues } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Modal, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomButton from "components/common/CustomButton";
import axios from "../axiosConfig";
import AdminForm from "../components/common/AdminForm";
import { useLocation } from "react-router-dom";

const AddAdmin = () => {
  const {
    register,
    handleSubmit,
    refineCore: { formLoading },
  } = useForm();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const office_id = location.pathname.split("/").pop() || "";
  console.log("🚀 ~ AddAdmin ~ office_id:", office_id);
  const [successMessage, setSuccessMessage] = useState(false);

  const onFinishHandler = async (data: FieldValues) => {
    if (data.password !== data.confirmPassword) {
      alert(t("passwordMismatch"));
      return;
    }

    const transformedData = {
      username: data.username,
      email: data.email,
      id_document: data.id_document,
      phone: data.phone,
      country: data.country,
      password: data.password,
    };

    try {
      const response = await axios.post(
        `superadmin/add_admin/${office_id}`,
        transformedData
      );

      if (response.status === 201) {
        console.log("API call successful, opening modal");
        setSuccessMessage(true);
      }
    } catch (error) {
      console.error("API Request Error:", error);
    }
  };

  const handleClose = () => {
    setSuccessMessage(false);
    navigate(-1);
  };

  return (
    <div>
      <AdminForm
        type="create"
        register={register}
        handleSubmit={handleSubmit}
        formLoading={formLoading}
        onFinishHandler={onFinishHandler}
        handleImageChange={(file: any) => {
          console.log("Image change handler not implemented yet");
        }}
        propertyImage={{
          name: "",
          url: "",
        }}
        agent={{
          email: "",
        }}
        agentData={undefined}
        control={undefined}
      />

      <Modal
        open={successMessage}
        onClose={handleClose}
        aria-labelledby="success-modal-title"
        aria-describedby="success-modal-description">
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: "75%", md: "50%", lg: "35%" }, // Responsive width
            bgcolor: "background.paper",
            boxShadow: 24,
            p: { xs: 2, sm: 3, md: 4 }, // Responsive padding
            textAlign: "center",
            borderRadius: 2,
          }}>
          <img
            src="/letsiconscheckfill.svg"
            alt="Success Icon"
            style={{
              width: "59px",
              height: "59px",
              maxWidth: "100%", // Ensure image scales down on smaller screens
            }}
          />
          <Typography
            id="success-modal-title"
            variant="h5" // Use a smaller variant for better responsiveness
            component="h2"
            sx={{ mt: { xs: 2, md: 5 }, mb: { xs: 2, md: 5 } }}>
            {" "}
            {/* Responsive margin */}
            تم إضافة مدير جديد بنجاح
          </Typography>
          <CustomButton
            type="button"
            title={t("confirm")}
            backgroundColor="#2E6CB6"
            color="#fcfcfc"
            handleClick={handleClose}
            style={{
              width: "100%", // Ensure button is full width on smaller screens
              maxWidth: "200px", // Set a max-width for larger screens
              margin: "0 auto", // Center the button
            }}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default AddAdmin;
