import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import { useTranslation } from "react-i18next";
import { LawyersCard, NotificationsCard, OffecesCard } from "components";
import axios from "../axiosConfig";

// Define the type for the fetched data
interface UserData {
  id: number;
  total_offices: string;
  // Add other relevant fields as needed
}

const Accounting: React.FC = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const user = localStorage.getItem("user");

  // useEffect(() => {
  //   fetch("https://elavvocato.com/api/superadmin/stats/all", {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   })
  //     .then((response) => {
  //       console.log("🚀 ~ .then ~ response:", response);
  //       if (!response.ok) {
  //         throw new Error(`HTTP error! Status: ${response.status}`);
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       console.log("🚀 ~ .then ~ data:", data);
  //       setUsers(data);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       console.log("🚀 ~ useEffect ~ error:", error);
  //       console.error("Error fetching data:", error);
  //       setLoading(false);
  //     });
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      console.log("🚀 ~ Lawyers ~ isError:");
      try {
        const response = await axios.get("/superadmin/stats/all", {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log("🚀 ~ fetchData ~ response:", response);
        setUsers(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  return (
    <Box mt="20px" sx={{ display: "flex", flexWrap: "wrap", gap: 4 }}>
      <Typography
        variant="h4"
        sx={{
          position: "absolute",
          top: 70,
          color: "#000",
          zIndex: 100,
        }}
      >
        {t("Main")}
      </Typography>

      {loading ? (
        <Typography>Loading...</Typography>
      ) : (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 4 }}>
          <Stack
            gap={2}
            direction="column"
            flexWrap="wrap"
            alignItems="center"
            zIndex={100}
          >
            <Card
              component={Link}
              to="/clients"
              sx={{
                maxWidth: "330px",
                width: "300px",
                height: "auto",
                padding: "20px",
                "&:hover": {
                  boxShadow: "0 22px 45px 2px rgba(176, 176, 176, 0.1)",
                },
                cursor: "pointer",
              }}
              elevation={4}
            >
              <OffecesCard
                prop={t("Offeces")}
                icroundNotifications="/hugeicons_office.svg"
                prop1={users.total_offices}
              />
            </Card>
            <Card
              component={Link}
              to="/vendors"
              sx={{
                maxWidth: "330px",
                width: "300px",
                height: "auto",
                padding: "20px",
                "&:hover": {
                  boxShadow: "0 22px 45px 2px rgba(176, 176, 176, 0.1)",
                },
                cursor: "pointer",
              }}
              elevation={4}
            >
              <NotificationsCard
                prop={t("Clients")}
                icroundNotifications="/user-icon4.svg"
                prop1={users.total_users}
              />
            </Card>
            <Card
              component={Link}
              to="/category"
              sx={{
                maxWidth: "330px",
                width: "300px",
                height: "auto",
                padding: "20px",
                "&:hover": {
                  boxShadow: "0 22px 45px 2px rgba(176, 176, 176, 0.1)",
                },
                cursor: "pointer",
              }}
              elevation={4}
            >
              <LawyersCard
                prop={t("Reports")}
                icroundNotifications="/carbon_report.svg"
                prop1=""
              />
            </Card>
          </Stack>
          <Stack
            gap={2}
            direction="column"
            flexWrap="wrap"
            alignItems="center"
            zIndex={100}
          >
            <Card
              component={Link}
              to="/vendors"
              sx={{
                maxWidth: "330px",
                width: "300px",
                height: "auto",
                padding: "20px",
                "&:hover": {
                  boxShadow: "0 22px 45px 2px rgba(176, 176, 176, 0.1)",
                },
                cursor: "pointer",
              }}
              elevation={4}
            >
              <LawyersCard
                prop={t("Lawyers")}
                icroundNotifications="/user-icon5.svg"
                prop1={users.total_lawyers}
              />
            </Card>
            <Card
              component={Link}
              to="/Items"
              sx={{
                maxWidth: "330px",
                width: "300px",
                height: "auto",
                padding: "20px",
                "&:hover": {
                  boxShadow: "0 22px 45px 2px rgba(176, 176, 176, 0.1)",
                },
                cursor: "pointer",
              }}
              elevation={4}
            >
              <LawyersCard
                prop={t("Notifications")}
                icroundNotifications="/icroundnotifications2.svg"
                prop1=""
              />
            </Card>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default Accounting;
