import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import CustomButton from "../common/CustomButton";
import { FormProps } from "interfaces/common";
import { styled } from "@mui/system";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: "12px",
    borderColor: "#D1D5DB",
    transition: "border-color 0.3s ease, box-shadow 0.3s ease",
    "& fieldset": {
      borderColor: "#D1D5DB",
    },
    "&:hover fieldset": {
      borderColor: "#2E6CB6",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#2E6CB6",
      boxShadow: `0 0 0 4px ${theme.palette.primary.light}33`,
    },
  },
  "& .MuiInputLabel-root": {
    fontSize: "0.9rem",
    color: "#6B7280",
  },
}));

const OfficeForm = ({
  type,
  register,
  handleSubmit,
  formLoading,
  agentData,
  onFinishHandler,
}: FormProps) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography
        fontSize={28}
        fontWeight={700}
        color="#1F2937"
        margin={2}
        textAlign="right"
        mb={2}
      >
        {type === "create" ? t("add_new_office") : t("editOffice")}
      </Typography>

      <Box
        mt={3}
        width={{
          xs: "100%", // Full width on extra small devices
          sm: "90%", // Slightly smaller on small devices
          md: "70%", // Moderate reduction on medium devices
          lg: "60%", // More reduction on large devices
          xl: "50%", // Half width on extra-large devices
        }}
        borderRadius="20px"
        padding="24px"
        bgcolor="#ffffff"
        boxShadow="0 2px 10px rgba(0, 0, 0, 0.1)"
      >
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            width: "100%",
          }}
          onSubmit={handleSubmit(onFinishHandler)}
        >
          <Stack direction="column" gap={4} width="100%">
            <StyledFormControl>
              <TextField
                required={type === "create"}
                id="office_name"
                label={t("enterOfficeName")}
                color="primary"
                defaultValue={agentData?.Name}
                inputProps={{ style: { fontSize: "1rem" } }}
                {...register("office_name", { required: type === "create" })}
                InputLabelProps={{
                  sx: {
                    direction: "rtl", // This is for the label
                    textAlign: "right", // Align the label text to the right
                  },
                }}
              />
            </StyledFormControl>
            <StyledFormControl>
              <TextField
                required={type === "create"}
                id="admin_name"
                label={t("enterAdminName")}
                color="primary"
                variant="outlined"
                defaultValue={agentData?.admin_username}
                inputProps={{ style: { fontSize: "1rem" } }}
                {...register("admin_name", { required: type === "create" })}
              />
            </StyledFormControl>
            <StyledFormControl>
              <TextField
                required={type === "create"}
                id="email"
                label={t("enterOfficeEmail")}
                color="primary"
                variant="outlined"
                defaultValue={agentData?.admin_email}
                inputProps={{ style: { fontSize: "1rem" } }}
                {...register("email", { required: type === "create" })}
              />
            </StyledFormControl>
            <StyledFormControl>
              <TextField
                required={type === "create"}
                id="phone"
                label={t("enterClientPhone")}
                type="number"
                color="primary"
                variant="outlined"
                defaultValue={agentData?.admin_phone}
                inputProps={{ style: { fontSize: "1rem" } }}
                {...register("phone", { required: type === "create" })}
              />
            </StyledFormControl>
          </Stack>

          <Stack direction={{ xs: "column", sm: "row" }} gap={3} width="100%">
            <StyledFormControl>
              <TextField
                required={type === "create"}
                id="address"
                label={t("address")}
                color="primary"
                variant="outlined"
                defaultValue={agentData?.Address}
                inputProps={{ style: { fontSize: "1rem" } }}
                {...register("address", { required: type === "create" })}
              />
            </StyledFormControl>
            <StyledFormControl>
              <TextField
                required={type === "create"}
                id="country"
                label={t("enterCountry")}
                color="primary"
                defaultValue={agentData?.country}
                variant="outlined"
                inputProps={{ style: { fontSize: "1rem" } }}
                {...register("country", { required: type === "create" })}
              />
            </StyledFormControl>
          </Stack>
          <Stack direction={{ xs: "column", sm: "row" }} gap={3} width="100%">
            <StyledFormControl>
              <TextField
                required={type === "create"}
                id="password"
                type="password"
                label={t("password")}
                color="primary"
                variant="outlined"
                inputProps={{ style: { fontSize: "1rem" } }}
                {...register("password", { required: type === "create" })}
              />
            </StyledFormControl>
            <StyledFormControl>
              <TextField
                required={type === "create"}
                id="confirm_password"
                type="password"
                label={t("confirmPassword")}
                color="primary"
                variant="outlined"
                inputProps={{ style: { fontSize: "1rem" } }}
                {...register("confirmPassword", {
                  required: type === "create",
                })}
              />
            </StyledFormControl>
          </Stack>

          <Box mt={2} textAlign="end">
            <CustomButton
              type="submit"
              title={type === "create" ? t("add_new_office") : t("editOffice")}
              backgroundColor="#2E6CB6"
              color="#ffffff"
            />
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default OfficeForm;
