import {
  Box,
  Typography,
  Button,
  Avatar,
  Dialog,
  DialogContent,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import GroupIcon from "@mui/icons-material/Group";
import { OfficeProps } from "interfaces/common";
import { useNavigate } from "react-router-dom";
import AdminList from "../common/AdminsList";
import { useState } from "react";

function checkImage(url: any) {
  const img = new Image();
  img.src = url;
  return img.width !== 0 && img.height !== 0;
}

const OfficeProfile = ({
  id,
  admin_name,
  avatar,
  email,
  country,
  phone,
  office_name,
  address,
}: OfficeProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openAdminList, setOpenAdminList] = useState(false);

  const handleEditOffice = () => {
    navigate(`/clients/edit/${id}`, {
      state: { agent: { ID: id } },
    });
  };

  const handleOpenAdminList = () => {
    setOpenAdminList(true);
  };

  const handleCloseAdminList = () => {
    setOpenAdminList(false);
  };

  return (
    <Box
      position="relative"
      bgcolor="#fff"
      minHeight="100vh"
      overflow="hidden"
      textAlign="right"
      fontFamily="Wahran"
      p={2}>
      <Typography
        position="relative"
        m={2}
        textAlign={{ xs: "center", md: "right" }}
        fontSize={{ xs: "20px", sm: "25px", md: "29px" }}>
        {t("Offices")}
      </Typography>

      <Box
        position="relative"
        width="100%"
        maxWidth="1068px"
        mx="auto"
        p={2}
        borderRadius="8px"
        bgcolor="#F5F7FB"
        border="1px solid #d9e3f1">
        <Box
          position="relative"
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          gap={3}
          alignItems="center">
          <Avatar
            src={checkImage(avatar) ? avatar : "/law-firm-logo-1@2x.png"}
            alt=""
            sx={{
              width: { xs: "80px", md: "112px" },
              height: { xs: "80px", md: "112px" },
              marginBottom: 2,
            }}
          />
          <Typography fontSize={{ xs: "20px", sm: "25px", md: "36px" }}>
            {office_name}
          </Typography>
        </Box>

        <Box
          mt={3}
          mb={10}
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          width="100%">
          {/* Office Name */}
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: "100%", sm: "45%", md: "30%" }}
            mb={2}>
            <Typography
              fontSize="16px"
              color="#111923"
              fontWeight={600}
              sx={{ marginBottom: "8px" }}>
              {t("Office Name")}
            </Typography>
            <Box
              p={2}
              border="1px solid #d9e3f1"
              borderRadius="8px"
              bgcolor="#fff">
              <Typography fontSize="16px">{office_name}</Typography>
            </Box>
          </Box>

          {/* Admin Name */}
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: "100%", sm: "45%", md: "30%" }}
            mb={2}>
            <Typography
              fontSize="16px"
              color="#111923"
              fontWeight={600}
              sx={{ marginBottom: "8px" }}>
              {t("Admin Name")}
            </Typography>
            <Box
              p={2}
              border="1px solid #d9e3f1"
              borderRadius="8px"
              bgcolor="#fff">
              <Typography fontSize="16px">{admin_name}</Typography>
            </Box>
          </Box>

          {/* Email */}
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: "100%", sm: "45%", md: "30%" }}
            mb={2}>
            <Typography
              fontSize="16px"
              color="#111923"
              fontWeight={600}
              sx={{ marginBottom: "8px" }}>
              {t("email")}
            </Typography>
            <Box
              p={2}
              border="1px solid #d9e3f1"
              borderRadius="8px"
              bgcolor="#fff">
              <Typography fontSize="16px">{email}</Typography>
            </Box>
          </Box>

          {/* Address */}
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: "100%", sm: "45%", md: "30%" }}
            mb={2}>
            <Typography
              fontSize="16px"
              color="#111923"
              fontWeight={600}
              sx={{ marginBottom: "8px" }}>
              {t("address")}
            </Typography>
            <Box
              p={2}
              border="1px solid #d9e3f1"
              borderRadius="8px"
              bgcolor="#fff">
              <Typography fontSize="16px">{address}</Typography>
            </Box>
          </Box>

          {/* Country */}
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: "100%", sm: "45%", md: "30%" }}
            mb={2}>
            <Typography
              fontSize="16px"
              color="#111923"
              fontWeight={600}
              sx={{ marginBottom: "8px" }}>
              {t("country")}
            </Typography>
            <Box
              p={2}
              border="1px solid #d9e3f1"
              borderRadius="8px"
              bgcolor="#fff">
              <Typography fontSize="16px">{country}</Typography>
            </Box>
          </Box>

          {/* Phone */}
          <Box
            display="flex"
            flexDirection="column"
            width={{ xs: "100%", sm: "45%", md: "30%" }}
            mb={2}>
            <Typography
              fontSize="16px"
              color="#111923"
              fontWeight={600}
              sx={{ marginBottom: "8px" }}>
              {t("phone")}
            </Typography>
            <Box
              p={2}
              border="1px solid #d9e3f1"
              borderRadius="8px"
              bgcolor="#fff">
              <Typography fontSize="16px">{phone}</Typography>
            </Box>
          </Box>
        </Box>

        <Box
          paddingX={10}
          display="flex"
          justifyContent="end"
          gap={2}
          width="100%">
          <Button
            onClick={handleEditOffice}
            variant="contained"
            startIcon={<EditIcon style={{ width: "20px", height: "20px" }} />}
            sx={{
              gap: 1,
              fontSize: "16px",
              backgroundColor: "#2E6CB6",
              borderRadius: "20px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.25)",
              width: { xs: "48%", sm: "45%", md: "193px" },
              "&:hover": {
                backgroundColor: "#1A4C96",
              },
            }}>
            {t("edit Office")}
          </Button>
          <Button
            onClick={handleOpenAdminList}
            variant="contained"
            startIcon={<GroupIcon style={{ width: "20px", height: "20px" }} />}
            sx={{
              gap: 1,
              fontSize: "16px",
              backgroundColor: "#2E6CB6",
              borderRadius: "20px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.25)",
              width: { xs: "48%", sm: "45%", md: "193px" },
              "&:hover": {
                backgroundColor: "#1A4C96",
              },
            }}>
            {t("View Admins")}
          </Button>
        </Box>
      </Box>

      {/* Admin List Modal */}
      <Dialog
        open={openAdminList}
        onClose={handleCloseAdminList}
        fullWidth
        maxWidth="md">
        <DialogContent>
          <AdminList id={id} /> {/* Pass the office ID to AdminList */}
        </DialogContent>
      </Dialog>
    </Box>
  );
};
// oldGold
export default OfficeProfile;
