import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Card,
  Stack,
  Avatar,
  TextField,
  Select,
  MenuItem,
  CardContent,
  IconButton,
} from "@mui/material";
import { AddButton } from "components";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Notes from "components/requests/Notes";
import DocumentsSection from "components/requests/DocumentsSection";
import axiosInstance from "axiosConfig";
import Lawyers from "pages/lawyers/lawyers";

// Define the type for the request data
interface RequestData {
  case_id?: number | null;
  case_type?: string | null;
  created_at?: string | null;
  defendant_name?: string | null;
  description?: string | null;
  document_type?: string | null;
  national_address?: string;
  id?: number;
  judgment_document_path?: string | null;
  lawyer_id?: number | null;
  location?: string | null;
  request_type?: string | null;
  user: User;
  plaintiff_name?: string;
  claim_value?: string;
  status?: string;
  notes?: Note[];
  files?: file[];
}

interface file {
  document_type: string;
  filename: string;
  path: string;
  id: number;
}

interface Note {
  id: number;
  title: string;
  content: string;
}

interface User {
  username?: string;
  photo_path?: string;
  address?: string;
  email?: string;
  gender?: string;
  id_document?: string;
  phone?: string;
  type?: string;
}

// Props types for child components
interface ComponentProps {
  data: RequestData | null;
}

const ProfileCardComponent: React.FC<ComponentProps> = ({ data }) => {
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        padding: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Avatar
          alt="Profile Picture"
          src={data?.user.photo_path}
          sx={{ width: 64, height: 64, mr: 2 }}
        />
        <Box sx={{ mr: 2, flexDirection: "column" }}>
          <Typography variant="h6">{data?.user.username || "N/A"}</Typography>
          <Typography variant="body2">{data?.user.address}</Typography>
        </Box>
      </Box>
      <Button
        onClick={() =>
          navigate("/clients/show", { state: { agent: data?.user } })
        }
        variant="contained"
        sx={{
          mt: 1,
          background: "#2e6cb6",
          mr: 20,
        }}
      >
        ملف المستخدم
      </Button>
    </Card>
  );
};

const MessageSectionComponent: React.FC<ComponentProps> = ({ data }) => (
  <Card sx={{ padding: "20px" }}>
    <Box sx={{ justifyContent: "space-between", display: "flex" }}>
      <Typography
        variant="body1"
        sx={{
          mt: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          padding: 1,
        }}
      >
        <Avatar
          alt="Profile Picture"
          src="/message-icon@2x.png"
          sx={{ width: 30, height: 30, ml: 2 }}
        />
        رسالة العميل
      </Typography>
      <Typography variant="subtitle2" color="textSecondary">
        {data?.created_at || "N/A"}
      </Typography>
    </Box>
    <Box
      sx={{
        mt: 2,
        padding: "10px",
        bgcolor: "#f9f9f9",
        borderRadius: 2,
        height: "300px",
      }}
    >
      {data?.description || "No message provided..."}
    </Box>
  </Card>
);

const CaseInfoComponent: React.FC<ComponentProps> = ({ data }) => (
  <Card
    sx={{
      padding: "20px",
      display: "flex",
      flexDirection: "column",
      gap: 2,
      direction: "ltr",
    }}
  >
    <Stack direction="row" spacing={2}>
      <TextField
        label="نوع القضية"
        defaultValue={data?.case_type || "N/A"}
        fullWidth
      />
      <TextField
        label="اسم المدعي"
        defaultValue={data?.plaintiff_name || "N/A"}
        fullWidth
      />
    </Stack>
    <Stack direction="row" spacing={2}>
      <TextField label="كود الطلب" defaultValue={data?.id || "N/A"} fullWidth />
      <TextField
        label="اسم المدعي عليه"
        defaultValue={data?.defendant_name || "N/A"}
        fullWidth
      />
    </Stack>
    <Stack direction="row" spacing={2}>
      <TextField
        label="المنطقة"
        defaultValue={data?.national_address || data?.location}
        fullWidth
      />
      <TextField
        label="قيمة المطالبة"
        defaultValue={data?.claim_value || "N/A"}
        fullWidth
      />
    </Stack>
  </Card>
);

const RequestDetails: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState(() => {
    const savedData = localStorage.getItem("data");
    return savedData ? JSON.parse(savedData) : location.state?.request;
  });

  useEffect(() => {
    if (location.state?.request !== undefined) {
      setData(location.state.request);
      localStorage.setItem("data", JSON.stringify(location.state.request));
    }
    if (location.state?.agent?.agent) {
      const handleRequest = async () => {
        try {
          const response = await axiosInstance.patch(
            `/admin/request/${data.id}`,
            { Lawyer: location.state?.agent?.agent }
          );
          console.log("🚀 ~ handleRequest ~ response:", response);
        } catch (error) {
          console.error("Error adding request:", error);
        }
      };
      handleRequest();
    }
  }, [location]);
  // Example using axiosInstance with FormData in a PATCH request
  const handleUpload = async (file: File) => {
    try {
      const formData = new FormData();
      formData.append("file", file); // File
      formData.append("document_type", "request");
      formData.append("filename", file.name);

      const response = await axiosInstance.post(
        `/admin/request/upload/${data?.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const uploadedFile = response.data.files[0];
      setData((prevData: { files: any }) => ({
        ...prevData,
        files: [...(prevData?.files || []), uploadedFile],
      }));
    } catch (error: any) {
      console.error(
        "Error uploading file:",
        error.response?.data || error.message
      );
    }
  };

  const handleDelete = (id: number) => {
    console.log("Deleting document with id:", id);
    // Handle document deletion logic
  };

  const handleDownload = (path: string) => {
    const fileUri = `https://files.elavvocato.com/${path}`;
    console.log("Downloading document from path:", fileUri);

    // Create a link element
    const link = document.createElement("a");
    link.href = fileUri;
    link.download = ""; // This will let the browser decide the filename or use the server's `Content-Disposition` header.

    // Append the link to the body (required for some browsers)
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Clean up
    document.body.removeChild(link);
  };

  const HeaderComponent: React.FC = () => {
    const [status, setStatus] = useState(data?.status || "pending");

    const handleChangeStatus = async (newStatus: string) => {
      try {
        // Update the status locally
        setStatus(newStatus);

        // Make an API call to update the status on the server
        const response = await axiosInstance.patch(
          `/admin/request/${data?.id}`,
          {
            status: newStatus,
          }
        );

        // Optionally, log or update the parent component with the response
        console.log("Status updated successfully:", response);
      } catch (error) {
        console.error("Error updating status:", error);
        // Revert status change in case of an error
        setStatus(data?.status || "pending");
      }
    };

    return (
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Select
          sx={{
            color: "#8DA7C8",
            borderColor: "#8DA7C8",
            width: "50%",
          }}
          variant="outlined"
          value={status}
          onChange={(e) => handleChangeStatus(e.target.value)}
        >
          <MenuItem value="pending">قيد المراجعة</MenuItem>
          <MenuItem value="in progress">مفتوح</MenuItem>
          <MenuItem value="done">مكتملة</MenuItem>
        </Select>
        <AddButton
          title={"اسند الى محامي"}
          handleClick={() =>
            navigate("/Requests/edit/lawyers", { state: { req: true } })
          }
          backgroundColor="#28876b"
          color="#fcfcfc"
          icon={
            <img
              className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[60px] h-[60px] overflow-hidden"
              alt=""
              src={"/Group 239.svg"}
            />
          }
        />
      </Box>
    );
  };

  return (
    <Box>
      <Typography fontSize={48} fontWeight={700} color="#11142d">
        {t("Requests")}
      </Typography>
      <Box display="flex" flexDirection="row" mt={2}>
        <Stack width="50%" spacing={3} px={2}>
          <Card
            component={Link}
            to={""}
            sx={{
              width: "100%",
              padding: "20px",
            }}
            elevation={4}
          >
            <CardContent sx={{ display: "flex", alignItems: "center" }}>
              <img
                className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[60px] h-[60px] overflow-hidden"
                alt=""
                src={
                  data?.request_type === "Consultation"
                    ? "/consultayion-icon.svg"
                    : data?.request_type === "enforcement"
                    ? "/Enforcement icon.png"
                    : data?.request_type === "Collecting"
                    ? "/collecting icon.png"
                    : data?.request_type === "Appointment"
                    ? "/appointment icon.png"
                    : data?.request_type === "Case"
                    ? "/case icon.png"
                    : "/default-icon.svg" // fallback for unknown types
                }
              />
              <Box sx={{ flexGrow: 1, padding: 3 }}>
                <Typography variant="h5" color="#255186">
                  {data?.request_type
                    ? t(data.request_type)
                    : "No description provided"}
                </Typography>
              </Box>
            </CardContent>
          </Card>
          <CaseInfoComponent data={data} />
          <Notes data={data?.notes} id={data?.id} />
        </Stack>
        <Stack width="50%" spacing={3} px={2}>
          <HeaderComponent />
          <ProfileCardComponent data={data} />
          <MessageSectionComponent data={data} />
          <DocumentsSection
            documents={
              data?.files?.map((file: { filename: any }) => ({
                ...file,
                name: file.filename,
              })) || []
            }
            onUpload={handleUpload}
            onDelete={handleDelete}
            onDownload={handleDownload}
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default RequestDetails;
