import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Modal,
  Stack,
  TextField,
} from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import UploadFileIcon from "@mui/icons-material/UploadFile";

interface Document {
  id: number;
  name: string;
  path: string;
}

interface DocumentsSectionProps {
  documents: Document[];
  onUpload: (file: File) => void;
  onDelete: (id: number) => void;
  onDownload: (path: string) => void;
}

const DocumentsSection: React.FC<DocumentsSectionProps> = ({
  documents,
  onUpload,
  onDelete,
  onDownload,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleUploadClick = () => {
    setIsModalOpen(true);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleUpload = () => {
    if (selectedFile) {
      onUpload(selectedFile);
      setSelectedFile(null);
      setIsModalOpen(false);
    }
  };
  const handleOpen = (path: string) => {
    const fileUri = `https://files.elavvocato.com/${path}`;
    console.log("Opening document from path:", fileUri);
    // Open the file in a new tab
    window.open(fileUri, "_blank");
  };
  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h6">المستندات</Typography>
        <Button
          variant="contained"
          startIcon={<UploadFileIcon />}
          onClick={handleUploadClick}
          sx={{
            gap: "8px",
            backgroundColor: "#2E6CB6",
            color: "#fff",
            "&:hover": { backgroundColor: "#255A94" },
          }}
        >
          إضافة مستند
        </Button>
      </Box>
      <Box>
        {documents.map((doc) => (
          <Stack
            key={doc.id}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            padding="10px"
            borderBottom="1px solid #e0e0e0"
          >
            <Typography variant="body2">{doc.name}</Typography>
            <Box>
              <IconButton onClick={() => onDownload(doc.path)}>
                <SaveAltIcon style={{ color: "#8DA7C8" }} />
              </IconButton>
              <IconButton onClick={() => onDelete(doc.id)}>
                <DeleteIcon style={{ color: "#8DA7C8" }} />
              </IconButton>
              <IconButton onClick={() => handleOpen(doc.path)}>
                <InfoIcon style={{ color: "#8DA7C8" }} />
              </IconButton>
            </Box>
          </Stack>
        ))}
      </Box>
      {/* Upload Modal */}
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="upload-document-modal"
        aria-describedby="upload-document-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: 400,
            borderRadius: 2,
          }}
        >
          <Typography id="upload-document-modal" variant="h6" component="h2">
            إضافة مستند
          </Typography>
          <Typography
            id="upload-document-modal-description"
            sx={{ mt: 2, mb: 2 }}
          >
            اختر ملفاً لتحميله (Word, Excel, PDF, أو صورة)
          </Typography>
          <input
            type="file"
            accept=".pdf,.doc,.docx,.xlsx,.xls,.jpg,.jpeg,.png"
            onChange={handleFileChange}
          />
          <Stack direction="row" justifyContent="flex-end" mt={2} spacing={2}>
            <Button onClick={() => setIsModalOpen(false)}>إلغاء</Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpload}
              disabled={!selectedFile}
            >
              تأكيد
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
};

export default DocumentsSection;
