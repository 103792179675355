import { FunctionComponent } from "react";
import { CardContent, Typography, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

export type OffecesCardType = {
  className?: string;
  prop?: string;
  icroundNotifications?: string;
  prop1?: string;

  /** Action props */
  onOffecesCardContainerClick?: () => void;
};

const OffecesCard: FunctionComponent<OffecesCardType> = ({
  prop,
  icroundNotifications,
  prop1,
}) => {
  const navigate = useNavigate();

  return (
    <CardContent sx={{ display: "flex", alignItems: "center" }}>
      {icroundNotifications && (
        <img
          className="absolute top-[calc(50%_-_31.5px)] left-[214px] w-[60px] h-[60px] overflow-hidden"
          alt=""
          src={icroundNotifications}
        />
      )}
      <Box sx={{ flexGrow: 1, padding: 3 }}>
        <Typography variant="h5" color="#255186">
          {prop}
        </Typography>
        <Typography variant="body1" color="#C84630">
          {prop1}
        </Typography>
      </Box>
    </CardContent>
  );
};

export default OffecesCard;
