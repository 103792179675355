import { useState } from "react";
import { useForm } from "@refinedev/react-hook-form";
import { FieldValues } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Modal, Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomButton from "components/common/CustomButton";
import axios from "../axiosConfig";
import OfficeForm from "components/common/OfficeForm";

const CreateOffice = () => {
  const {
    register,
    handleSubmit,
    refineCore: { formLoading },
  } = useForm();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [successMessage, setSuccessMessage] = useState(false);

  const onFinishHandler = async (data: FieldValues) => {
    // Check if passwords match
    if (data.password !== data.confirmPassword) {
      alert(t("passwordsDoNotMatch"));
      return;
    }

    // Transform data
    const transformedData = {
      office_name: data.office_name,
      admin_name: data.admin_name,
      avatar: data.avatar,
      phone: data.phone,
      email: data.email,
      address: data.address,
      country: data.country,
      password: data.password,
      confirmPassword: data.confirmPassword,
      id_document: "1235", // Static value for example
    };

    try {
      // API request
      const response = await axios.post(
        "/superadmin/create_office",
        transformedData
      );

      // If successful, show success modal
      if (response.status === 201) {
        console.log("API call successful, opening modal");
        setSuccessMessage(true);
      }
    } catch (error) {
      console.error("API Request Error:", error);
    }
  };

  // Handle modal close
  const handleClose = () => {
    setSuccessMessage(false);
    navigate("/clients");
  };

  return (
    <div>
      {/* Office Form Component */}
      <OfficeForm
        type="create"
        register={register}
        handleSubmit={handleSubmit}
        formLoading={formLoading}
        onFinishHandler={onFinishHandler}
        handleImageChange={(file: any) => {
          console.log("Image change handler not implemented yet");
        }}
        propertyImage={{
          name: "",
          url: "",
        }}
        agent={{
          email: "",
        }}
        agentData={undefined}
        control={undefined}
      />

      {/* Success Modal */}
      <Modal
        open={successMessage}
        onClose={handleClose}
        aria-labelledby="success-modal-title"
        aria-describedby="success-modal-description">
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "35%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
            borderRadius: 2,
          }}>
          <img
            src="/letsiconscheckfill.svg"
            alt="Success Icon"
            style={{ width: "59px", height: "59px" }}
          />
          <Typography
            id="success-modal-title"
            variant="h4"
            component="h2"
            m={5}>
            {t("officeAddedSuccessfully")}
          </Typography>
          <CustomButton
            type="button"
            title={t("confirm")}
            backgroundColor="#2E6CB6"
            color="#fcfcfc"
            handleClick={handleClose}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default CreateOffice;
