import React, { useState } from "react";
import { FieldValues } from "react-hook-form";
import { useGetIdentity } from "@refinedev/core";
import ClientForm from "components/client/ClientForm";
import { Modal, Box, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useForm } from "@refinedev/react-hook-form";
import { useNavigate } from "react-router-dom";
import { CustomButton } from "components";
import axios from "../../axiosConfig";

const CreateClients = () => {
  const { data: user } = useGetIdentity({
    v3LegacyAuthProviderCompatible: true,
  });

  const {
    refineCore: { onFinish, formLoading },
    register,
    handleSubmit,
  } = useForm();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState(false);

  const onFinishHandler = async (data: FieldValues) => {
    // Handle date transformation
    const { day, month, year, password, confirmPassword, ...rest } = data;
    const formattedDate = `${year}-${month}-${day}`;

    // Handle password confirmation
    if (password !== confirmPassword) {
      alert(t("passwordsDoNotMatch"));
      return;
    }

    const transformedData = {
      ...rest,
      password,
    };

    try {
      const response = await axios.post("admin/create_user", transformedData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = response.data;
      console.log("API Response:", result);

      // Show success message
      setSuccessMessage(true);
    } catch (error) {
      console.log("API Request Error:", error);
    }
  };

  const handleClose = () => {
    setSuccessMessage(false);
    navigate("/clients");
  };

  return (
    <div>
      <ClientForm
        type="Create"
        register={register}
        onFinish={onFinish}
        formLoading={formLoading}
        handleSubmit={handleSubmit}
        agent={user}
        onFinishHandler={onFinishHandler}
        handleImageChange={function (file: any): void {}}
        propertyImage={{
          name: "",
          url: "",
        }}
        control={undefined}
        agentData={undefined}
      />
      <Modal
        open={successMessage}
        onClose={handleClose}
        aria-labelledby="success-modal-title"
        aria-describedby="success-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "35%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
            borderRadius: 2,
          }}
        >
          <img
            src="/letsiconscheckfill.svg"
            alt="Next"
            style={{ width: "59px", height: "59px" }}
          />
          <Typography
            id="success-modal-title"
            variant="h4"
            component="h2"
            m={5}
          >
            {t("userAddedSuccessfully")}
          </Typography>
          <CustomButton
            type="submit"
            title={t("confirm")}
            backgroundColor="#2E6CB6"
            color="#fcfcfc"
            handleClick={handleClose}
          />
          {/* <Button variant="contained" onClick={handleClose}> */}

          {/* </Button> */}
        </Box>
      </Modal>
    </div>
  );
};

export default CreateClients;
