import { useForm } from "@refinedev/react-hook-form";
import { FieldValues } from "react-hook-form";
import Form from "components/common/Form";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../axiosConfig";
import { Box, Modal, Typography } from "@mui/material";
import CustomButton from "components/common/CustomButton";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const CreateProperty = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const Data = location.state?.agent;
  const {
    refineCore: { onFinish, formLoading },
    register,
    handleSubmit,
    control,
  } = useForm();
  const [successMessage, setSuccessMessage] = useState(false);
  const handleClose = () => {
    setSuccessMessage(false);
    navigate("/vendors");
  };
  const { t } = useTranslation();
  const onFinishHandler = async (data: FieldValues) => {
    const updateData = {
      username: data.username,
      email: data.email,
      phone: data.phone,
      user_type: Data.type,
      id_document: data.id_document,
      ...(data.password && { password: data.password }), // Only include password if it has a value
    };

    try {
      // Make the PUT request to update the user
      const response = await axiosInstance.patch(
        `/superadmin/update_user/${Data.id}`,
        updateData
      );
      const responseData = response.data;
      if (response.status === 200) {
        console.log("User updated successfully:", responseData);
        setSuccessMessage(true);
      } else {
        console.log("Update failed:", responseData.message);
      }
    } catch (error) {
      console.log("Error updating user:", error);
    }
  };
  return (
    <>
      <Form
        type="Create"
        register={register}
        onFinish={onFinish}
        formLoading={formLoading}
        // handleSubmit={() => navigate("/clients")}
        handleSubmit={handleSubmit}
        agentData={Data}
        control={control}
        // handleImageChange={handleImageChange}
        onFinishHandler={onFinishHandler}
        handleImageChange={function (file: any): void {}}
        propertyImage={{
          name: "",
          url: "",
        }} // propertyImage={propertyImage}
        agent={{
          email: "",
        }}
      />
      <Modal
        open={successMessage}
        onClose={handleClose}
        aria-labelledby="success-modal-title"
        aria-describedby="success-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "35%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
            borderRadius: 2,
          }}
        >
          <img
            src="/letsiconscheckfill.svg"
            alt="Next"
            style={{ width: "59px", height: "59px" }}
          />
          <Typography
            id="success-modal-title"
            variant="h4"
            component="h2"
            m={5}
          >
            {t("userupdateedSuccessfully")}
          </Typography>
          <CustomButton
            type="submit"
            title={t("confirm")}
            backgroundColor="#2E6CB6"
            color="#fcfcfc"
            handleClick={handleClose}
          />
          {/* <Button variant="contained" onClick={handleClose}> */}

          {/* </Button> */}
        </Box>
      </Modal>
    </>
  );
};
export default CreateProperty;
